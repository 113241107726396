<template>
  <div class="join main-frame">
    <!-- 内容1 -->
    <div class="cont-1 relative">
      <el-image lazy class="banner-1" :src="require('@/assets/join/join_img1.png')"  />
      <div class="text-1 white left">
        <h2 class="h-b gapB24"><span>{{$t("join_h1_1")}}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{$t("join_h1_2")}}</span></h2>
        <p class="p2 fz16 fw400">{{$t("join_h1_3")}}</p>
        <p class="p2 fz16 fw400">{{$t("join_h1_4")}}</p>  
      </div>
    </div>
    <!-- 内容2-->
    <div class="cont-2 padd100-100">
      <h2 class="h-1 gapB64">{{$t("join_h2_1")}}</h2>
      <div class="home-con relative">
        <el-image  lazy class="banner-2"  :src="require('@/assets/join/join_img2.png')"  />
        <div class="text-2 left">
          <h2 class="h-4 fz18 fw700 gapB12">{{$t("join_con_1")}}</h2>
          <p class="p2 fz16 fw400 gapB32">{{$t("join_con_2")}}</p>
          <h2 class="h-4 fz18 fw700 gapB12">{{$t("join_con_3")}}</h2>
          <p class="p2 fz16 fw400">{{$t("phone")}}</p>  
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tab:1,
      isMini:false, 
    }
  },
  mounted(){
    this.checkScreen()
  },
  methods: {
    // 屏幕检测变化
    checkScreen() {
      var _this = this
      if (document.body.clientWidth < 992) {
          _this.isMini = true
      } else {
          _this.isMini = false
      }
      window.addEventListener('resize', () => {
        if (document.body.clientWidth < 992) {
            _this.isMini = true
          } else {
            _this.isMini = false
          }
      })
    },
    // 菜单切换
    tabFun(n) {
      this.tab = n   
    },
  }
};
</script>


<style lang="scss" scoped>
.banner-1{
  height: 480px;
}
.join {
  width: 100%;
}
/*1*/
.cont-1 .p2{
  color: white
}
.text-1{
  position: absolute;
  left: 360px;
  top: 128px;
  z-index: 3;
  .p2{
    line-height: 24px;
  }
}
.cont-2 .h-3{
  color: #16193c;
}
.cont-2{
  box-sizing: border-box;
  .home-con{
    width: 1120px;
    margin: 0 auto;
  }
  .banner-2{
    height: 480px;
  }
  .text-2{
    position: absolute;
    left: 680px;
    top: 100px;
    z-index: 3;
    .h-4{
      color: #181d24;
      line-height: 26px;
    }
    .p2{
      color: #393f47;
      line-height: 24px;
    }
  }
}


/*  991*/
@media (max-width: 992px) {

}
@media (max-width: 428px) {
  
}
</style>